import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  CircularProgress,
  Backdrop,
  Divider,
} from "@material-ui/core";
import CKEditor from "ckeditor4-react";
import Content from "../../../../components/Content";
import { defaultAlert } from "../../../../helpers/validation/alerts";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { MailSchema } from "../../../../helpers/validation/Schemas/Configuration/Communication/MailSchema";
import {
  getConfigurationMail,
  updateConfigurationMail,
} from "../../../../services/endpoints/configuration/communication/endpoints";

export default function MailParameters() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordEmailTemplateData, setPasswordEmailTemplateData] =
    useState("");
  const [userEmailTemplateData, setUserEmailTemplateData] = useState("");

  const inputRef = useRef(null);

  const { register, errors, setError, handleSubmit, reset, control, setValue } =
    useForm({
      validationSchema: MailSchema,
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {
        identificacao_email: "",
        email: "",
        usuario: "",
        senha_email: "",
        host_smtp: "",
        port_smtp: "",
        status: false,
      },
    });

  useEffect(() => {
    setIsLoading(true);
    const handleFetchConfigsValues = async () => {
      await getConfigurationMail()
        .then(({ data }) => {
          reset(data.configuracoes);
          setUserEmailTemplateData(
            data.configuracoes.template_email_recovery_usuario
          );
          setPasswordEmailTemplateData(
            data.configuracoes.template_email_recovery_senha
          );
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    setTimeout(() => {
      handleFetchConfigsValues();
    }, 2000);
    // eslint-disable-next-line
  }, []);

  function handlePasswordEmailTemplateEditor(event) {
    setPasswordEmailTemplateData(event.editor.getData());
  }

  function handleUserEmailTemplateEditor(event) {
    setUserEmailTemplateData(event.editor.getData());
  }

  async function handleForm(data) {
    setIsLoading(true);
    data.template_email_recovery_usuario = userEmailTemplateData;
    data.template_email_recovery_senha = passwordEmailTemplateData;
    updateConfigurationMail(data)
      .then(() => {
        setIsLoading(false);
        defaultAlert(
          "Parâmetros de comunicação via e-mail atualizados com sucesso!",
          true,
          "Entendi",
          "success"
        );
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let { errors } = error.response.data;
          Object.keys(errors).map((erro) => {
            return setError(erro, "notMatch", errors[erro]);
          });
        }
        setIsLoading(false);
      });
  }

  return (
    <Content>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container className={classes.gridRoot}>
        <Box className={classes.paperStyle} component={Paper} elevation={2}>
          <Typography component="h1" variant="h6">
            Editar parâmetros comunicação de e-mail do sistema
          </Typography>
          <form
            method="POST"
            onSubmit={handleSubmit((data) => handleForm(data))}
          >
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.identificacao_email}
                className={classes.flexItems}
              >
                <InputLabel>Identificação Remetente</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="identificacao_email"
                />
                <FormHelperText>
                  {errors?.identificacao_email?.message}
                </FormHelperText>
              </FormControl>
              <FormControl error={!!errors.email} className={classes.flexItems}>
                <InputLabel>E-mail</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="email"
                />
                <FormHelperText>{errors?.email?.message}</FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.usuario}
                className={classes.flexItems}
              >
                <InputLabel>Usuário</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="usuario"
                />
                <FormHelperText>{errors?.usuario?.message}</FormHelperText>
              </FormControl>
              <FormControl
                error={!!errors.senha_email}
                className={classes.flexItems}
              >
                <InputLabel>Senha</InputLabel>
                <Controller
                  as={<Input type="password" />}
                  rules={{ required: true }}
                  control={control}
                  name="senha_email"
                />
                <FormHelperText>{errors?.senha_email?.message}</FormHelperText>
              </FormControl>
            </Box>
            <Box className={classes.flexContainer}>
              <FormControl
                error={!!errors.host_smtp}
                className={classes.flexItems}
              >
                <InputLabel>Host SMTP</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="host_smtp"
                />
                <FormHelperText>{errors?.host_smtp?.message}</FormHelperText>
              </FormControl>
              <FormControl
                error={!!errors.port_smtp}
                className={classes.flexItems}
              >
                <InputLabel>Porta SMTP</InputLabel>
                <Controller
                  as={<Input />}
                  rules={{ required: true }}
                  control={control}
                  name="port_smtp"
                />
                <FormHelperText>{errors?.port_smtp?.message}</FormHelperText>
              </FormControl>
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.flexContainer}>
              <Box
                className={classes.paperStyleCkEditor}
                component={Paper}
                elevation={2}
              >
                <Typography component="span" variant="h6">
                  Modelo: E-mail de Recuperação de senha
                </Typography>
                <Box className={classes.flexContainerLegend}>
                  <CKEditor
                    config={{versionCheck: false}}
                    data={passwordEmailTemplateData}
                    onChange={handlePasswordEmailTemplateEditor}
                  />
                  <Typography component="span" variant="caption">
                    <br />
                    Tags disponíveis para substituição: [nome] - Nome do usuário
                    do sistema que solicitou a recuperação de senha;
                    [link_forgot_pass] - Link de recuperação de senha
                  </Typography>
                </Box>
              </Box>
              <Box
                className={classes.paperStyleCkEditor}
                component={Paper}
                elevation={2}
              >
                <Typography component="h1" variant="h6">
                  Modelo: E-mail de Recuperação de usuário
                </Typography>
                <Box className={classes.flexContainerLegend}>
                  <CKEditor
                    config={{versionCheck: false}}
                    data={userEmailTemplateData}
                    onChange={handleUserEmailTemplateEditor}
                  />
                  <Typography component="span" variant="caption">
                    <br />
                    Tags disponíveis para substituição: [nome] - Nome do usuário
                    do sistema que solicitou a recuperação de senha;
                    [cd_usuario] - Esta tag é substituida pelo email do usuário;
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.flexContainerButton}>
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="primary"
              >
                Atualizar parâmetros
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>
    </Content>
  );
}

const useStyles = makeStyles((theme) => ({
  gridRoot: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  flexTopicSeparator: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
  },
  clientImage: {
    borderRadius: "4px",
    height: "200px",
    width: "200px",
    objectFit: "contain",
    cursor: "pointer",
  },
  flexImageContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  flexContainerButton: {
    marginTop: theme.spacing(5),
    display: "flex",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    justifyContent: "center",
  },
  flexItems: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
    width: "100%",
  },
  flexItemImage: {
    alignItems: "center",
  },
  flexItem: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.between("xs", "sm")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
      maxWidth: "33%",
    },
    width: "100%",
  },
  flexContainerLegend: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  paperStyleCkEditor: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));
