import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Link from "@ckeditor/ckeditor5-link/src/link";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor";
import List from "@ckeditor/ckeditor5-list/src/list";
import Table from "@ckeditor/ckeditor5-table/src/table";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount";
import PageBreak from "@ckeditor/ckeditor5-page-break/src/pagebreak";

export const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Heading,
    Link,
    PasteFromOffice,
    Base64UploadAdapter,
    Image,
    ImageUpload,
    ImageResize,
    ImageStyle,
    Alignment,
    FontFamily,
    FontSize,
    FontColor,
    List,
    Table,
    WordCount,
    PageBreak,
  ],
  toolbar: [
    "heading",
    "fontFamily",
    "fontSize",
    "fontColor",
    "bold",
    "italic",
    "|",
    "alignment:left",
    "alignment:right",
    "alignment:center",
    "alignment:justify",
    "|",
    "numberedList",
    "bulletedList",
    "|",
    "link",
    "pageBreak",
    "|",
    "insertTable",
    "tableColumn",
    "mergeTableCells",
    "|",
    "imageResize",
    "imageResize:original",
    "imageUpload",
    "imageStyle:full",
    "imageStyle:side",
    "|",
    "selectAll",
    "undo",
    "redo",
  ],
  language: "pt",
  versionCheck: false
};

/*
image: {
    upload: {
      types: ['jpg', 'jpeg']
    } 
  }
  */