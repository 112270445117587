import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  Icon,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CKEditor from "ckeditor4-react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Content from "../../../../components/Content";
import {
  getAllVersionedPatientTerms,
  createPatientTerms,
} from "../../../../services/endpoints/termsPatient/endpoints";
import { green } from "@material-ui/core/colors";
import { getFormatedDateUI } from "../../../../helpers/dateHelpers";
import { defaultAlert } from "../../../../helpers/validation/alerts";

export default function TermsMaintenancePatient() {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [dataEditor, setDataEditor] = useState({});
  const [isNewTerm, setIsNewTerm] = useState(false);
  const [terms, setTerms] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchTerms = async () => {
      await getAllVersionedPatientTerms()
        .then(({ data }) => {
          setTerms(data.list_termo_de_uso_paciente);
        })
        .catch(() => {
          setIsLoadingList(false);
        });
    };
    fetchTerms();
  }, []);

  function handleEditorData(event) {
    setDataEditor({ ...dataEditor, descricao: event.editor.getData() });
  }

  function handleEditTermNameChange(event) {
    event.persist();
    setDataEditor({ ...dataEditor, nome_termo: event.target.value });
  }

  function handleListTermsItemClick(id, itemState) {
    if (itemState !== "BLOCKED") {
      const termItemClicked = terms.filter((term) => term.id_termo === id);
      setDataEditor(termItemClicked[0]);
      setIsEditing(itemState === "BLOCKED" ? false : true);
      setIsNewTerm(false);
    } else {
      defaultAlert(
        "Este termo de uso se encontra em uma versão desatualizada não pode mais ser atualizado ou modificado!",
        false,
        "Ok",
        "question",
        "Termo de uso obsoleto"
      );
    }
  }

  function handleNewTerm() {
    const onlyVersions = [];
    terms.map((term) => {
      return onlyVersions.push(parseInt(term.versao));
    });
    const lastVersion = parseInt(dataEditor.versao);
    setDataEditor({ versao: lastVersion + 1 });
    setIsEditing(true);
    setIsNewTerm(true);
  }

  async function createOrEditTerm(operation) {
    setIsLoadingList(true);
    setIsLoading(true);
    if (operation === "NEW") {
      const { nome_termo, descricao, versao } = dataEditor;
      const jsonCreate = {
        nome_termo,
        descricao: escape(descricao),
        versao,
      };
      createPatientTerms(jsonCreate)
        .then(({ data }) => {
          defaultAlert(
            "Termo criado com sucesso",
            true,
            "Entendi",
            "success",
            "Tudo certo!"
          );
          const termIndex = terms.length - 1;
          let newTerms = [...terms];
          newTerms[termIndex] = {
            ...newTerms[termIndex],
            tp_situacao: "I",
          };
          dataEditor.id_termo = data.id_termo;
          dataEditor.versao = data.versao;
          setTerms([...newTerms, dataEditor]);
          setDataEditor({
            ...dataEditor,
            nome_termo: "",
            descricao: "",
            id_termo: "",
            versao: ""
          });
          setIsLoadingList(false);
          setIsLoading(false);
          setIsEditing(false);
          setIsNewTerm(false);
        })
        .catch(() => {
          setIsLoadingList(false);
          setIsLoading(false);
        });
    }
  }

  return (
    <>
      <Content>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box className={classes.paperStyle} component={Paper} elevation={3}>
          <Grid container>
            <Grid item xs={12} md={4} className={classes.gridItems}>
              <Box className={classes.termsListTitleBox}>
                <Typography variant="h6" component="div">
                  Termos de uso do paciente
                </Typography>
                {
                  terms.length < 1 ? ( <GreenButton
                    className={classes.newTermButton}
                    onClick={handleNewTerm}
                    variant="outlined"
                    color="default"
                    size="small"
                    endIcon={<Icon>add</Icon>}
                  >
                    Novo
                  </GreenButton>) : 
                  ("")
                }
              </Box>
              <List className={classes.root}>
                {isLoadingList ? (
                  <>
                    {[...Array(8).keys()].map((index) => {
                      return (
                        <Skeleton
                          key={index}
                          animation="pulse"
                          variant="rect"
                          height={0}
                        />
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
                {terms
                  ? terms
                      .slice(0)
                      .reverse()
                      .map((term) => (
                        <ListItem
                          key={term.id_termo}
                          button
                          style={
                            {
                              backgroundColor:
                                term.tp_situacao === "I"
                                  ? "#f1f1f1"
                                  : "none",
                              cursor:
                                term.tp_situacao === "I"
                                  ? 'no-drop'
                                  : 'default'
                            }
                          }
                          alignItems="flex-start"
                          className={classes.termListItem}
                          onClick={() =>
                            handleListTermsItemClick(
                              term.id_termo,
                              term.tp_situacao === "I"
                                ? "BLOCKED"
                                : ""
                            )
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                component="span"
                                className={classes.termListText}
                                variant="caption"
                                display="block"
                                color="textPrimary"
                              >
                                {
                                  <strong>
                                    {`${term.nome_termo.toUpperCase()}- V${
                                      term.versao
                                    }`}
                                  </strong>
                                }
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography
                                  noWrap
                                  className={classes.termListText}
                                  component="span"
                                  display="block"
                                  variant="caption"
                                  color="textPrimary"
                                >
                                  {term.descricao.replace(/<.*?>/g, "")}
                                </Typography>
                                <Typography
                                  noWrap
                                  component="small"
                                  display="block"
                                  variant="caption"
                                >
                                  Criado em:{" "}
                                  {getFormatedDateUI(term.dh_cadastro)} - Criado
                                  por: {term.responsavel}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                      ))
                  : "Não existem termos cadastrados"}
              </List>
            </Grid>
            <Grid item xs={12} md={8} className={classes.editorGrid}>
              <Typography variant="h6">{}</Typography>
              <Box className={classes.editorBox}>
                <TextField
                  disabled={!isEditing}
                  label="Titulo do termo de uso"
                  variant="standard"
                  className={classes.titleInput}
                  fullWidth
                  value={dataEditor.nome_termo || ""}
                  onChange={handleEditTermNameChange}
                />
                <CKEditor
                  config={{versionCheck: false}}
                  readOnly={!isEditing}
                  data={dataEditor.descricao}
                  onChange={handleEditorData}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end">
              {isEditing ? (
                  <Button
                    variant="contained"
                    onClick={() => createOrEditTerm("NEW")}
                    color="primary"
                  >
                    {terms.length < 1 ? "Criar termo" : "Atualizar versão"}
                  </Button>
                ) : (
                    ""
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Content>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
  paperStyle: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflow: "scroll",
    maxHeight: "420px",
  },
  termListItem: {
    padding: 0,
    paddingRight: "10px",
    borderBottom: "solid 1px lightgrey",
  },
  termListText: {
    width: "90%",
  },
  editorGrid: {
    [theme.breakpoints.between("md", "lg")]: {
      borderLeft: "solid 1px lightgrey",
      paddingLeft: theme.spacing(1),
    },
  },
  editorBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleInput: {
    marginBottom: theme.spacing(2),
  },
  termsListTitleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px lightgrey",
    paddingBottom: theme.spacing(1),
  },
  newTermButton: {
    marginRight: theme.spacing(1),
  },
}));

const GreenButton = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
